<template>
  <div>
    <div class="content">
      <!-- 预览文件 -->
      <div class="leftbox" v-if="filelist.length !== 0">
        <!-- 合同文件 -->
        <div class="fileinfo">文件信息</div>
        <el-menu
          :default-openeds="openeds"
          class="el-menu-vertical-demo"
          v-if="filelist.length !== 0"
          @open="handleClick"
          :unique-opened="true"
        >
          <el-submenu
            :index="index.toString()"
            v-for="(item, index) of filelist"
            :key="index"
            v-show="item.child && item.child.length > 0"
          >
            <template slot="title">
              <span @click="clickLabel($event, index)">{{ item.label }}</span>
            </template>
            <el-submenu
              :index="index + '-' + indexon"
              v-for="(elem, indexon) of item.child"
              :key="indexon"
              class="SmallTitle"
              :class="{ titleSpan: titleSpan(elem.documentName) }"
            >
              <template slot="title">{{ elem.documentName }} </template>
              <el-menu-item
                v-for="(objKey, key) of elem.fileListDetailVOS"
                :key="key"
                :index="index + '-' + indexon"
                @click="changeFile(objKey)"
                :class="{ active: objKey.currentFileId === activeId }"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="objKey.fileName"
                  placement="top"
                  :enterable="false"
                >
                  <!-- <div class="objKeyFileName">
                    {{(objKey.signTaskType===1?'【补签】':'') + objKey.fileName }}
                  </div> -->
                  <div class="objKeyFileName">
                    <!-- 20220816 赵红兵让删除补签判断 -->
                    <!-- <span style="color: blue">{{
                      objKey.signTaskType === 1 ? "【补签】" : ""
                    }}</span> -->
                    {{ objKey.fileName }}
                  </div>
                </el-tooltip>
              </el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </div>
      <!-- 文件预览 -->
      <div class="bigflie">
        <titleTemplate :name="title" v-if="title">
          <template slot="operation">
            <span
              v-for="(item, index) of titlecomfig"
              :key="index"
              class="operation"
            >
              {{ item.label }}{{ item.value }}
            </span>
          </template>
        </titleTemplate>
        <!-- 文件预览组件 -->
        <div class="fliebox" :class="{ flieboxHeight: title }">
          <div class="filedown">
            <div class="info">
              <div v-if="fileSource">
                文件来源：{{ fileSource === "0" ? "线上生成" : "人工上传" }}
              </div>
              <span v-if="implementSituation === 0 || implementSituation === 1"
                >落实情况：<span class="pdr15">{{
                  implementSituation ? "已落实" : "未落实"
                }}</span>
              </span>
              <span v-if="originalCopies"
                >份数：<span class="pdr15">{{ originalCopies }}</span>
              </span>
              <span v-if="memo">
                <div class="row">
                  <div>落实备注：</div>
                  <el-tooltip
                    effect="dark"
                    :content="memo"
                    placement="top"
                    :disabled="disabledarr"
                    :enterable="false"
                  >
                    <span class="pdr15 memo" ref="valuebox">
                      {{ memo ? memo : "无" }}
                    </span>
                  </el-tooltip>
                </div>
              </span>
            </div>
             <!-- <base-button
              icon="iconfont iconbanli"
              label="在线编辑"
              @click="onlineEdit(currentFileId)"
              style="margin-left:10px"
              v-if="showOnlineBtn()"
            ></base-button> -->
            <!-- 暂时隐藏 -->
            <!-- <base-button
            style="margin-left:10px"
              icon="iconfont iconshangchuan"
              label="文件上传"
              btnType="upload"
              accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xls,.xlsx"
              :http-request="function (param) {
              return newUploadFile(param);
              }
              "
              v-if="false"
            ></base-button> -->
            <base-button
              icon="iconfont iconxiazai"
              label="文件下载"
              @click="downFile(currentFileId)"
              v-if="preViewShow"
              style="margin-left:10px"
            ></base-button>
            <!-- <base-button
              icon="iconfont iconbanli"
              label="文件履历"
              @click="viewResume()"
              style="margin-left:10px"
              v-if="showOnlineBtn()"
            ></base-button> -->
              <!-- v-if="showOnlineBtn()" -->

          </div>
          <pre-view
            :count="count"
            :fileId="currentFileId"
            :fileType="currentFileType"
            v-if="preViewShow"
            class="fileViewBox"
          ></pre-view>
        <div class="fileBottom"></div>
        </div>
      </div>
      <!-- 审批内容 -->
      <div class="rightbox" v-if="showStep">
        <template v-if="type === 'E'">
          <titleTemplate name="审批信息">
            <template slot="operation" v-if="showRevise()">
              <!-- <base-button label="修改" @click="jump"></base-button> -->
              <slot name= 'titleRight'></slot>
            </template>
          </titleTemplate>
          <div>
            <BaseForm
              :showBtns="false"
              :componentList="formConfigListCofig"
              :formAttrs="{
                model: formdata,
                labelWidth: '50px',
              }"
              class="formStyle"
              ref="fromdata"
            ></BaseForm>
          </div>
          <div class="submit">
            <baseButton
              label="提交"
              @click.prevent="submit(true)"
            ></baseButton>
            <baseButton label="关闭" @click.prevent="close"></baseButton>
          </div>
        </template>
        <!-- 审批详情数据组件 -->
        <auditTimeline
          :dataList="finReviewOpinionInfoVOS"
          :auditInfo="
            type === 'E' ? { fileMakeName: '进行中' } : { fileMakeName: '' }
          "
          :class="{ maxheight: type === 'E' }"
        ></auditTimeline>
      </div>
    </div>

    <!-- 在线编辑弹窗 -->
    <online-edit-dialog
     :onlineEditVisible.sync="onlineEditVisible"
    :count="editCount"
    :fileId="editFileId"
    :fileType='editFileType'
    :fileEditData='fileEditData'
    >
    ></online-edit-dialog>
     <!-- 文件处理履历 -->
    <document-history
    :docHistoryVisible.sync="docHistoryVisible"
    :docHistoryData='docHistoryData'
    :count="docHistoryCount"
    :fileTypeLists='fileTypeLists'
    ></document-history>
  </div>
</template>
<script>
import DocumentHistory from './document-history.vue'
import onlineEditDialog from './online-edit-dialog.vue'
// import BaseTabs from '@/components/common/tabs/src/base-tabs.vue'
// import BaseTabPane from '@/components/common/tabs/src/base-tab-pane.vue'
import titleTemplate from './titleTemplate.vue'
import BaseForm from '@/components/common/base-form//base-form'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import auditTimeline from './auditTimeline.vue'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import { progressApi } from '@/api/businessApi'
import { fileAPi, downAPi } from '@/api/fileApi'
import PreView from '@/components/pre-view/pre-view.vue'
// import upload from './upload.vue'
import { downFile, getPara } from '@/utils/devUtils'
import { allSupportFileTypes } from '@/components/pre-view/pre-view'
// import { getDictLists } from '@/filters/fromDict'
import { getManager, getRoles } from '@/api/sys/user'
import { formConfigListx } from './confing'
import { financeAdmittanceApi, instanceNodeApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'

export default {
  components: {
    auditTimeline,
    baseButton,
    BaseForm,
    titleTemplate,
    PreView,
    onlineEditDialog,
    DocumentHistory
  },
  data () {
    return {

      // 在线编辑内容
      onlineEditVisible: false,
      editFileId: '',
      editCount: 0,
      fileEditData: {},
      editFileType: '',

      disabledarr: false,
      preViewShow: true,
      form: {},
      formdata: {
        reviewStatus: 10
      },
      uploadFileId: '',
      uploadFileName: '',
      activeId: '',
      activeNames: '1',
      backToProcessOptions: [], // 回退流程选项
      // assigneeOptions: [], // 办理人
      dataInfo: {},
      count: 1,
      selfType: '',
      selfFileId: '',
      implementSituation: '',
      originalCopies: '',
      memo: '',
      fileSource: '',
      downloadZipId: '',
      fileName: '',
      riskManagerOptions: [], // 风控经理下拉
      operationPost: '1', // 是否是运营岗 0 不是：1是
      riskManagerPost: '1', // 是否是风控经理 0 不是：1是
      openeds: [],
      fileListDetailVOS: {},
      businessType: '', // 流程

      // 文件履历相关数据
      docHistoryCount: 0,
      docHistoryVisible: false,
      fileTypeLists: [],
      docHistoryData: {},
      // 特殊处理
      isSpecial: ''
    }
  },
  computed: {
    // openeds () {
    //   return ['0', '0-0']
    // },
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    },
    api () {
      return progressApi
    },
    formConfigListCofig () {
      return this.formConfig.length > 0 ? this.formConfig : formConfigListx(this)
    },
    currentFileType () {
      return this.selfType || this.fileType
    },
    currentFileId () {
      return this.selfFileId || this.fileId
    },
    Moptions () {
      return this.getRiskManagerOptions()
    }
  },
  props: {
    isShowRevise: {
      // 是否显示修改按钮
      type: Boolean,
      default: false
    },
    open: {
      // 是否显示回退流程
      type: Boolean,
      default: false
    },
    showStep: {
      // 是否显示申请详情
      type: Boolean,
      default: true
    },
    filelist: {
      // 审核文件列表
      type: Array,
      default: () => []
    },
    processStatus: {
      // 审核进度节点 融资决议-FINANCING_RESULT, 合同文件-CONTRACT, 要素落实-ELEMENT_IMPLEMENTATION
      type: String,
      default: ''
    },
    finReviewOpinionInfoVOS: {
      type: Array,
      default: () => []
    },
    titlecomfig: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    formConfig: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'E' // 默认审核 ；V 查看
    },
    fileId: String,
    fileType: String,
    fileSources: String
    // businessType: String // 业务类型 'htwj'  'ysls'
  },
  created () {
    this.getRole()
    this.getRiskManagerOptions()
    this.filterFileLists()
  },
  mounted () {
    this.isSpecial = getPara(Storage.getSession('examine').actionUrl, 'backNode')
  },
  watch: {
    currentFileId: {
      handler () {
        this.$nextTick(() => {
          this.count++
          this.getwidth()
        })
      }
    },
    // 监听进来的值，默认显示第一个文件
    filelist: {
      handler () {
        try {
          this.filelist.forEach((item, index) => {
            if (item.child.length > 0) {
              // throw ()
              this.businessType = item.key
              item.child.forEach((ele, i) => {
                if (ele?.fileListDetailVOS.length > 0) {
                  this.changeFile(this.filelist[index].child[i]?.fileListDetailVOS[0])
                  this.openeds = [index, index + '-' + '0']
                  return false
                }
              })
              throw new Error()
            }
          })
        } catch (e) {
        }
        this.changeFile(this.filelist[0].child[0]?.fileListDetailVOS[0])
        this.openeds = ['0', '0-0']
      },
      deep: true
    },
    fileSources: {
      handler (val) {
        this.fileSource = val
      }
    }
  },
  methods: {
    // 是否展示编辑按钮
    showOnlineBtn () {
      // console.log(Storage.getSession('examine').finBusuinessInfoVO.processNodeName, '法务审核')
      if (this.preViewShow && this.businessType === 'htwj' && this.type === 'E' && Storage.getSession('examine').finBusuinessInfoVO.processNodeName !== '法务审核') {
        if (this.fileListDetailVOS.currentFileFileSuffix === 'doc' || this.fileListDetailVOS.currentFileFileSuffix === 'docx') {
          return true
        } else {
          return false
        }
      }
    },
    // 筛选当前的文件类型
    filterFileLists () {
      this.filelist.forEach(item => {
        if (item.child.length > 0) {
          item.child.forEach(ele => {
            if (!this.fileTypeLists.some(e => e.documentId === ele.documentId)) {
              this.fileTypeLists.push({ documentId: ele.documentId, documentName: ele.documentName })
            }
          })
        }
      })
    },
    // 文件履历查看
    viewResume () {
      const row = this.filterFileData()
      this.docHistoryData = row
      this.filterFileLists()
      this.docHistoryCount++
      this.docHistoryVisible = true
    },
    // 过滤编辑履历的数据
    filterFileData () {
      const row = JSON.parse(JSON.stringify(this.fileListDetailVOS))
      this.filelist.forEach(item => {
        if (item.child.length > 0) {
          item.child.forEach(ele => {
            if (ele.documentId === this.fileListDetailVOS.documentId) {
              this.$set(row, 'documentName', ele.documentName)
            }
          })
        }
      })
      return row
    },
    // 在线编辑
    async  onlineEdit () {
      const row = this.filterFileData()
      // 复制文件
      const res = await fileAPi.fileCopy({ fileId: row.fileId })
      this.fileEditData = JSON.parse(JSON.stringify(row))
      // 编辑复制的文件
      this.editFileId = res.data.keyId
      this.editFileType = res.data.fileName.substr(res.data.fileName.lastIndexOf('.') + 1, 4)
      this.onlineEditVisible = true
      this.$nextTick(() => {
        this.editCount++
      })
    },
    // 风控经理riskManager
    getRiskManagerOptions () {
      getManager({ roleCode: 'fkjl_code' }).then(res => {
        this.riskManagerOptions = res.data.map(item => {
          return { riskManagerId: item.userId, riskManagerName: item.nickName, riskUserName: item.userName }
        })
      })
    },

    newUploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const data = {
        fileDetailKeyId: this.fileListDetailVOS.keyId
      }

      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          data.fileId = res.data.keyId
          this.api.reUpload(data).then(res => {
            this.success('文件上传成功')
            this.$emit('fileUpdate', '')
          })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    titleSpan (fileName) {
      return fileName.length > 16
    },
    // 点击标题默认选中第一个
    clickLabel (e, index) {
      // e.stopPropagation()
      // this.changeFile(this.filelist[index].child[0])
      // if (this.businessType === 'ysls') {
      //   this.changeFile(this.filelist[index].child[0])
      // } else if (this.businessType === 'htwj') {
      //   this.changeFile(this.filelist[index].fileListDetailVOS[0])
      // }
    },
    sendData (key) {
      return this.formData[key] ? this.formData[key] : ''
    },
    // 获取内容宽度
    getwidth () {
      const dom = this.$refs.valuebox
      if (dom) {
        this.disabledarr = dom.offsetWidth >= dom.scrollWidth
      }
    },
    // 改变审核结果查回退节点
    changeReviewStatus (data) {
      if (data && data === 40) {
        // const params = { taskId: Storage.getSession('examine').taskId }
        // financeAdmittanceApi.approvalBackGetNodeList(params).then(res => {
        //   console.log(res.data)
        //   if (res.data && res.data.length > 0) {
        //     this.backToProcessOptions = res.data
        //     console.log(this.backToProcessOptions, '++=')
        //   }
        // })
        const params = {
          flag: '1',
          instanceId: Storage.getSession('examine').instanceId,
          taskId: Storage.getSession('examine').taskId
        }

        instanceNodeApi.getInstanceNode(params).then(res => {
          const taskList = res.data
          // res.data.forEach(item => {
          //   if (item.transferredUser) {
          //   // 移交数据存在
          //     taskList.push({
          //       ...item,
          //       name: item.taskName,
          //       assigneeList: [
          //         {
          //           id: item.transferredUser,
          //           name: item.transferredUserName
          //         }
          //       ]
          //     })
          //   } else {
          //     taskList.push({
          //       ...item,
          //       name: item.taskName,
          //       assigneeList: [
          //         {
          //           id: !item.originalUser ? item.assignee : item.originalUser,
          //           name: !item.originalUserName
          //             ? item.assigneeName
          //             : item.originalUserName
          //         }
          //       ]
          //     })
          //   }
          // })
          this.backToProcessOptions = taskList
          if (this.isSpecial) {
            this.backToProcessOptions = taskList.filter(it => it.id === this.isSpecial)
          }
        })
      } else {
        this.backToProcessOptions = []
        // this.$set(this.formdata, 'reviewType', '')
        this.$set(this.formdata, 'taskDefinitionKey', '')
        this.$set(this.formdata, 'taskDefinitionName', '')
      }
    },
    // 回退节点选择 后设置办理人
    changeBackToProcess (data) {
      if (data) {
        const [obj] = this.backToProcessOptions.filter(item => item.id === data)

        if (obj) {
          // this.assigneeOptions = obj.assigneeList
          // this.$set(this.formdata, 'assignee', obj.assigneeList[0].id)
          // this.$set(this.formdata, 'reviewType', obj.reviewType)
          this.$set(this.formdata, 'taskDefinitionKey', obj.id)
          this.$set(this.formdata, 'taskDefinitionName', obj.name)
        }
      } else {
        // this.assigneeOptions = []
        // this.$set(this.formdata, 'assignee', '')
        // this.$set(this.formdata, 'reviewType', '')
        this.$set(this.formdata, 'taskDefinitionKey', '')
        this.$set(this.formdata, 'taskDefinitionName', '')
      }
    },
    // 上传文件成功后赋值文件名
    inputSuccess (name) {
      this.formdata.uploadFileName = name
    },
    // 修改选中文件
    changeFile (elem) {
      if (!elem) {
        return
      }
      this.implementSituation = elem.implementSituation ? elem.implementSituation : ''
      this.originalCopies = elem.originalCopies
      this.memo = elem.memo
      this.fileName = elem.fileName
      this.activeId = elem.currentFileId
      this.selfFileId = elem.currentFileId
      this.fileSource = elem.fileSource
      this.selfType = elem.currentFileFileSuffix
      this.downloadZipId = elem.pdfFileId && elem.pdfFileId !== '0' ? elem.fileId : ''
      this.preViewShow = true
      this.fileListDetailVOS = elem
      if (elem.currentFileId.toString() === '0') {
        this.warning('未找到文件')
        this.preViewShow = false
        return
      }
      this.selfType = this.selfType.toLowerCase()
      if (!this.selfType || !allSupportFileTypes.includes(this.selfType)) {
        this.warning(
          elem.suffix
            ? `不能预览的文件类型：[${this.selfType}]`
            : '文件类型不能为空'
        )
        this.preViewShow = false
      }
    },
    // 关闭当前页面
    close () {
      this.$router.push({ path: this.tags.path })
    },
    handleClick (index, indexPath) {
      console.log(index, indexPath, this.filelist)
      if (indexPath.length > 1) {
        let key = indexPath[1]
        key = key.split('-')
        key = key[key.length - 1]
        console.log(this.filelist, indexPath, indexPath[0], key, 222)
        this.changeFile(
          this.filelist[indexPath[0]].child[key].fileListDetailVOS[0]
        )
      }
    },
    // 上传附件
    uploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'WSF')
      fileAPi.file(formData).then((res) => {
        if (res.success) {
          this.success('文件上传成功')
          this.formdata.uploadFileId = res.data.keyId
          this.formdata.uploadFileName = res.data.fileName
        } else {
          this.error('文件上传成功')
        }
      })
    },
    // 查角色信息
    getRole () {
      getRoles(this.$store.state.user.userInfo.userId).then(res => {
        if (res.data) {
          // 是否为运营岗
          if (res.data.some(item => item.roleCode === 'wsf_wybm')) {
            this.operationPost = '1'
            // 如果角色为运营岗时 且环节在融资申请，自动选中审批结果为通过，且禁用
            if (this.processStatus === 'ADMIT') {
              this.$set(this.formdata, 'reviewStatus', 10)
            }
          } else {
            this.operationPost = '0'
          }
          // 是否为风控经理
          if (res.data.some(item => item.roleCode === 'fkjl_code')) {
            this.riskManagerPost = '1'
          } else {
            this.riskManagerPost = '0'
          }
        }
      })
    },
    // 是否显示修改按钮  》角色为运营岗&在评审会环节
    showRevise () {
      if ((this.riskManagerPost === '1' || this.operationPost === '1') && (this.processStatus === 'REVIEW' || this.processStatus === 'SUPPLEMENT')) {
        return true
      } else {
        return false
      }
    },
    // 提交审核内容-new
    submit () {
      const listData = JSON.parse(sessionStorage.getItem('examine'))
      const [obj] = this.riskManagerOptions.filter(item => item.riskManagerId === this.formdata.riskManagerId)
      this.formdata.riskManagerName = obj ? obj.riskManagerName : ''
      this.formdata.processStatus = listData.finBusuinessInfoVO.statusCode
      this.formdata.businessId = listData.finBusuinessInfoVO.keyId
      this.formdata.operationPost = this.operationPost
      this.formdata.taskId = listData.taskId
      this.formdata.taskName = listData.taskName
      this.formdata.actionUrl = listData.actionUrl
      this.formdata.isfApproval = false
      this.formdata.batchNum = listData.finBusuinessInfoVO.batchNum
      // 在审核的时候，返回修改40.但是现在合同制作、签章流程传20
      // const statusCodeArr = ['CONTRACT', 'SIGNATURE', 'SIGNATURE_SIGN', 'CONTRACT_SIGN']
      // if (statusCodeArr.some(item => item === listData.finBusuinessInfoVO.statusCode) && this.formdata.reviewStatus === 40) {
      //   this.formdata.reviewStatus = 20
      // }

      const data = { ...this.formdata }

      if (data.reviewStatus === 40 && data.radio) {
        data.reviewStatus = data.radio
      }

      this.$refs.fromdata.validate(valid => {
        if (valid) {
          financeAdmittanceApi.approvalCommit(data).then(res => {
            if (res.data) {
              this.success('提交成功')
              this.$router.push({ path: this.tags.path })
            }
          })
        }
      })
    },
    // 文件下载
    downFile (fileId) {
      if (fileId || this.downloadZipId) {
        const params = {
          keyId: this.downloadZipId ? this.downloadZipId : fileId
        }
        downAPi
          .download(params)
          .then((res) => {
            downFile(
              res,
              this.fileName
                ? this.fileName
                : '融资决议书' + '.' + this.currentFileType
            )
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.titleSpan {
  .el-submenu__title {
    height: auto;
    line-height: 33px;
    white-space: normal;
  }
}
.objKeyFileName {
  width: 240px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.row {
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.bigflie {
  flex: 1;
  // width:calc(100%-700px) ;
  height: calc(100% - 12px);
  background: #fff;
  display: flex;
  flex-direction: column;
  .fliebox {
    background: #f9f9f6;
    width: 100%;
    // height: calc(100% - 43px);
    height: 90%;
     .fileBottom{
    height:  40px;
    width: 100%;
    background-color: #fff;
  }
  }
  .flieboxHeight {
    // flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 85ppx);
  }
  .title {
    background: #fff;
  }

}
.pdr15 {
  padding-right: 15px;
}
.memo {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.maxheight {
  height: auto;
  // overflow-y: auto;
}
.fileitem {
  background: linear-gradient(90deg, #88ACFF 0%, #FFFFFF 100%);;
  display: flex;
  width: 100%;
  padding: 0 9px;
  flex-wrap: wrap;
  height: 90px;
  overflow: auto;
  .filename {
    font-size: 14px;
    font-weight: 400;
    color: #38373a;
    cursor: pointer;
    padding: 8px 0;
  }
  .filename:hover {
    color: #b2662f;
  }
  > div {
    padding-right: 15px;
  }
}
.mt40 {
  margin-top: 40px;
  height: calc(100% - 40px) !important;
}
.operation {
  font-size: 14px;
  font-weight: 400;
  color: #38373a;
  padding-left: 25px;
  padding-right: 10px;
}
.pd20 {
  padding: 0 20px;
}
.submit {
  padding: 9px 15px;
  text-align: center;
  background: #F5F5F5;
}
/deep/ textarea {
  width: 100%;
  height: 180px;
  border-color: #2862E7;
  resize: none;
  outline-color: #2862E7;
}
.rigtcontent {
  height: calc(100% - 140px);
}
.content {
  height: 100%;
  display: flex;
  width: 100%;
  .leftbox {
    width: 307px;
    background: #ffffff;
    height: calc(100% - 30px);
    margin-right: 10px;
    overflow: auto;
    padding: 20px 10px 0 10px;
    /deep/ .el-menu {
      border-right: 0;
      background: #f9f9f6;
      .is-opened {
        border-top: 3px solid #2862E7;
      }
      .active {
        color: #FFFFFF !important;
        background: linear-gradient(90deg, #4A7CF0 0%, #FFFFFF 100%);
      }
      .el-menu-item {
        color: #38373a;
      }
      .el-submenu__title {
        background: #EBF1FF;
        color: #2862E7;
        font-size: 14px;
        border-bottom: 1px solid #B6CCFF;
        .el-submenu__icon-arrow {
          color: #2862E7;
        }
      }
      .el-menu-item {
        &:hover {
          background: linear-gradient(90deg, #88ACFF 0%, #FFFFFF 100%);;
        }
      }
    }
  }
  .rightbox {
    margin-left: 8px;
    width: 450px;
    background: #ffffff;
    height: calc(100% - 15px);
    overflow: auto;
    .formStyle {
      /deep/ .el-form-item__content {
        height: auto !important;
      }
      /deep/ .el-button {
        height: 34px;
        padding: 0;
        width: 90px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconshangchuan {
          font-size: 19px;
        }
      }
    }
  }
  .title {
    font-weight: bold;
    color: #38373a;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > div:nth-child(1) {
      height: 24px;
      border-left: 6px solid #2862E7;
    }
    > div:nth-child(2) {
      padding-left: 10px;
      border-bottom: 1px solid #DEEFE8;
      height: 57px;
      line-height: 57px;
      flex: 1;
    }
  }
}
.SmallTitle {
  background: #fff !important;
  /deep/ .el-submenu__title {
    background: #fff !important;
  }
}
.filedown {
  height: 40px;
  .info {
    flex: 1;
    color: #757380;
    font-size: 14px;
  }
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 5px;
}
.fileViewBox{
  flex: 1;
  width: 100%;
  height:80%;
}
.fileinfo{
  color: #2862E7;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
</style>
